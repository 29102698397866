
  import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
  import { translations, notificationAlerts, eventHandler } from "@/mixins";
  import {mapGetters} from "vuex";
  
  @Component({
    components: {
      Events: () => import("./components/Events.vue"),
    },
    computed: {
      ...mapGetters({
  
      }),
    },
    mixins: [translations, notificationAlerts, eventHandler],
  })
  export default class Kiosks extends Vue {
    translate!: Function;
    tab: any = 1
  
    mounted() {
    }
  

  
    get dropDownText(): any {
      if (this.tab === 1) {
        return this.translate('Kiosk')
      }                 
    }
  
  
  
  
  }
  